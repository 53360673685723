<template>
  <div class="chatbot-container">
    <transition name="fade">
      <div v-if="isChatVisible" :class="{ 'chatbox': true, 'maximized': isMaximized, 'minimized': !isMaximized }">
        <div class="chatbox-header">
          <span class="chatbox-title">Chat</span>
          <div class="chatbox-controls">
            <button class="control-button close" @click="closeChat">✖</button>
            <button class="control-button maximize" @click="toggleMaximize">{{ isMaximized ? '🔽' : '🗖' }}</button>
          </div>
        </div>
        <div class="welcome-message">
          Welcome to our chatbot, please note that this is a work in progress so there will be changes in future
          implementation.
        </div>
        <div class="chat-messages" ref="chatMessagesContainer">
          <div v-for="(msg, index) in messages" :key="index" :class="['message', msg.user !== 'bot' ? 'user' : 'bot']">
            <div class="message-content" v-html="msg.message"></div>
          </div>
          <div v-if="showTypingAnimation" class="typing-indicator">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
        </div>

        <div class="chat-input-container">
          <textarea v-model="message" placeholder="Type your message..." rows="3"
            @keydown.enter.prevent="sendMessage" :disabled="showTypingAnimation || botIsTyping"></textarea>
          <div class="button-container">
            <button class="clear" @click="clearChat" :disabled="showTypingAnimation || botIsTyping">Clear</button>
            <button class="send" @click="sendMessage" :disabled="showTypingAnimation || botIsTyping">Send</button>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="!isChatVisible" class="chatbot-button" @click="toggleChat">
      <span class="chat-icon">💬</span>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/services/RepositoryFactory";
const aiRepository = RepositoryFactory.get("aiRepository");

export default {
  name: 'ChatbotButton',
  data() {
  return {
      isChatVisible: false,
      isMaximized: false,
      message: "",
      messages: [],
      welcomeMessage: {
        user: "bot",
        message: "Hello! How can I assist you today?"
      },
      showTypingAnimation: false,
      botIsTyping: false
    };
  },
  mounted() {
    this.messages.push(this.welcomeMessage);
  },
  methods: {
    toggleChat() {
      this.isChatVisible = !this.isChatVisible;
    },
    closeChat() {
      this.isChatVisible = false;
    },
    toggleMaximize() {
      this.isMaximized = !this.isMaximized;
    },
    async sendMessage() {
      if (this.message.trim() !== '') {
        this.messages.push({ user: "user", message: this.message });
        this.$nextTick(() => this.scrollToBottom());
        
        // Show typing indicator
        this.showTypingAnimation = true;

        var requestBody = {
          content: [this.message]
        };

        this.message = '';

        setTimeout(async () => {
          const response = await aiRepository.chat(requestBody);

          this.showTypingAnimation = false;

          if (response && response.success) {
            const fullMessage = this.formatResponse(response.data.current.response.join('\n'));
            
            // Start typing animation
            this.typeMessage(fullMessage);
          }
        });
      }
    },
    async typeMessage(message) {
			this.botIsTyping = true;
			const typingSpeed = 10; // Adjust typing speed here (ms per character)
			let index = 0;
			this.messages.push({ user: "bot", message: "" });
			const currentMessage = this.messages[this.messages.length - 1];
			
			const typeInterval = setInterval(() => {
				if (index < message.length) {
					currentMessage.message += message[index++];
				}
				// Check if user is near the bottom before scrolling
				if (this.isNearBottom()) {
					this.$nextTick(() => this.scrollToBottom());
				}
				
				if (index >= message.length) {
					this.botIsTyping = false;
					clearInterval(typeInterval);
				}
			}, typingSpeed);
		},
    clearChat() {
      if(this.showTypingAnimation) 
        return;
      this.messages = [];
    },
    formatResponse(text) {
      return text
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
        .replace(/\n\n/g, '<br><br>')
        .replace(/\n/g, '<br>');  
    },
    isNearBottom() {
			const container = this.$refs.chatMessagesContainer;
			return container.scrollHeight - container.scrollTop <= container.clientHeight + 50; // 50px margin
		},
		scrollToBottom() {
			const container = this.$refs.chatMessagesContainer;
			if (container) {
				container.scrollTop = container.scrollHeight;
			}
		},
  },
	watch: {
		messages: {
			handler() {
				this.$nextTick(() => {
					if (this.isNearBottom()) {
						this.scrollToBottom();
					}
				});
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.chatbox {
  position: fixed;
  bottom: 20px;
  /* Default position */
  right: 20px;
  /* Default position */
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 20% !important;
  height: 50% !important;
}

.maximized {
  width: 50% !important;
  height: 80% !important;
}

.chatbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.chatbox-title {
  font-size: 16px;
  font-weight: bold;
}

.chatbox-controls {
  display: flex;
}

.control-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.control-button.close {
  color: #dc3545;
  /* Red color */
}

.control-button.maximize {
  color: #007bff;
  /* Blue color */
}

.control-button:hover {
  opacity: 0.8;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
  scroll-behavior: smooth; /* Smooth scrolling */
}

.chat-input-container {
  padding: 10px;
  border-top: 1px solid #ddd;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

.chat-input-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  align-items: center;
}

.button-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.button-container button.clear {
  border: 1px solid #dc3545;
  background-color: white;
  color: #dc3545;
  width: 80px;
  /* Fixed width for Clear button */
}

.button-container button.clear:hover {
  background-color: #dc3545;
  color: white;
}

.button-container button.send {
  background-color: #007bff;
  color: white;
  flex: 1;
  /* Make Send button take the remaining space */
  margin-left: 10px;
}

.button-container button.send:hover {
  background-color: #0056b3;
}

.chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 24px;
  font-family: Arial, sans-serif;
  z-index: 1001;
}

.chatbot-button:hover {
  background-color: #0056b3;
}

/* Container for each message */
.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  background-color: #f9f9f9;
}

.message {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-start;
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
}

.message-content {
  max-width: 70%;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.message.user .message-content {
  background-color: #007bff;
  color: white;
}

.message.bot .message-content {
  background-color: #f1f1f1;
  color: #333;
}

.message-content::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
}

.message.user .message-content::before {
  border-left: 10px solid #007bff;
  right: -10px;
  top: 10px;
  border-right: none;
}

.message.bot .message-content::before {
  border-right: 10px solid #f1f1f1;
  left: -10px;
  top: 10px;
  border-left: none;
}

/* Adjustments for messages container */
.chat-messages p {
  margin: 0;
}

.welcome-message {
  background-color: #fff3cd;
  /* Light yellow background */
  color: #856404;
  /* Dark yellow text */
  padding: 10px;
  border: 1px solid #ffeeba;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  margin: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.typing-indicator .dot {
  background-color: #007bff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  animation: typing 1.5s infinite;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes typing {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

textarea:disabled, 
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;  
}

.button-container button:disabled:hover {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
  box-shadow: none;
}

</style>
